const searchCompaniesQueryV2Deprecated =
    `query SearchCompanies(
      $name: String
      $limit: Int
      $industries: [String!]
      $currentCountProfile: IntRangeFilter
      $order: SortingOrder
  ) {
  
      findAllCompanies(
          name: $name
          limit: $limit
          sortBy: "currentCountProfile"
          industries: $industries
          currentCountProfile: $currentCountProfile
          order: $order
      ) {
        name
        id
        uuid
        industry
        currentCountProfile
        historicalCountProfile
        linkedinUrl
        mainWebsite
      }
  }`
  
  export default searchCompaniesQueryV2Deprecated;