import { env } from '@common/env';
import { createRoot } from 'react-dom/client';
import App from './views/app/App';
import Providers from './components/Providers';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import 'antd/dist/antd.min.css';
import '@common/mixpanel';
import '@styles/index.scss';

const clientSideID = String(env('REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID'));

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID,
    context: {
      kind: 'org',
      key: 'aura_frontend',
    },
  });

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <LDProvider>
      <Providers>
        <App />
      </Providers>
    </LDProvider>
  );
})();
