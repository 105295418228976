import createAuth0Client from '@auth0/auth0-spa-js';
import { env } from '@common/env';
import { Event, registerUserInfo, setProfile, track } from '@common/mixpanel';
import { IS_TEST } from '@common/platform';
import {
  getShareProjectInfoStatus,
  getUpdateInfoStatus,
  getUserRole,
  getUserSubscriptions,
} from '@util/api';
import { useLocalStorage } from '@util/hooks';
import AppLoading from '@views/app/AppLoading';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getMaintenanceStatus } from '../util/api';
import MaintenancePage from '../views/app/Maintenance';

const AUTH0_DOMAIN = String(env('AUTH0_DOMAIN'));
const AUTH0_CLIENT_ID = String(env('AUTH0_CLIENT_ID'));
const AUTH0_AUDIENCE = String(env('AUTH0_AUDIENCE'));
const REDIRECT_URL_KEY = 'redirectUrl';
const ANONYMOUS_USER = {
  email: 'anonymous@example.com',
  email_verified: false,
  name: '',
  nickname: '',
  picture: '',
  sub: '',
  id: '',
  updated_at: '',
  userMeta: {},
  appMeta: {},
};

// returns promise
const getAuthClient = () =>
  createAuth0Client({
    domain: AUTH0_DOMAIN,
    client_id: AUTH0_CLIENT_ID,
    redirect_uri: window.location.origin,
    audience: AUTH0_AUDIENCE,
  });

let _accessToken;
const getAccessToken = () => _accessToken;

const AuthContext = createContext();
const useAuthContext = () => useContext(AuthContext);

function AuthContextProvider({ children }) {
  const [accessToken, setAccessToken] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(!IS_TEST);
  const [user, setUser] = useState(ANONYMOUS_USER);
  const [auth0Client, setAuth0] = useState({});
  const [authenticating, setAuthenticating] = useState(!IS_TEST);
  const [tokenHasExpired, setTokenHasExpired] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [canShowWalkThrough, setCanShowWalkThrough] = useState(false);
  const [loginCounts, setLoginCounts] = useState(0);
  const [showShareProjectInfo, setShowShareProjectInfo] = useState(false);
  const [showJpUpdateInfo, setShowJpUpdateInfo] = useState(false);
  const [showIhUpdateInfo, setShowIhUpdateInfo] = useState(false);
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState({});
  const [isTrialUser, setIsTrialUser] = useState(false);
  const [isTrialExpired, setIsTrialExpired] = useState(false);
  const [expiredIn, setExpiredIn] = useState(false);

  const history = useHistory();

  const [firstTimeUser, saveFirstTimeUser] = useLocalStorage('firstTimeUser');

  const _setUser = user => {
    // we actually don't know the key to access userMeta and appMeta
    // ahead of time because they might change...
    // An example of the keys we are trying to find
    // https://delta-mvp-production.herokuapp.com/appmeta
    // https://delta-mvp-production.herokuapp.com/usermeta

    let userMetaKey = '';
    let appMetaKey = '';

    for (const key in user) {
      if (key.endsWith('usermeta')) {
        userMetaKey = key;
      }

      if (key.endsWith('appmeta')) {
        appMetaKey = key;
      }
    }

    const userObj = {
      ...user,
      id: user.sub,
      userMeta: user[userMetaKey],
      appMeta: user[appMetaKey],
    };

    delete userObj[userMetaKey];
    delete userObj[appMetaKey];

    setUser(userObj);
  };

  const enableWalkThrough = () => {
    setCanShowWalkThrough(false);
    saveFirstTimeUser({ homepage: false, diligence: false });
  };

  useEffect(() => {
    // In tests, we don't want to call any of this.
    if (IS_TEST) return;

    if (
      !window.localStorage.getItem(REDIRECT_URL_KEY) &&
      history.location.pathname !== '/' &&
      history.location.pathname !== '/logout'
    ) {
      window.localStorage.setItem(REDIRECT_URL_KEY, history.location.pathname);
    }

    (async () => {
      const client = await getAuthClient();
      setAuth0(client);
      track(Event.HOME_PAGE_SCREEN);
      if (
        window.location.pathname !== '/welcome' &&
        !window.location.search.includes('error=access_denied') &&
        !window.location.search.includes('error=unauthorized') &&
        (window.location.search.includes('code=') ||
          window.location.search.includes('state='))
      ) {
        await client.handleRedirectCallback();
        const redirect =
          window.localStorage.getItem(REDIRECT_URL_KEY) ||
          window.location.pathname;
        history.replace(redirect);
        window.localStorage.removeItem(REDIRECT_URL_KEY);

        track(Event.LOGIN);
      }

      if (window.location.search.includes('searchId=')) {
        window.localStorage.setItem(
          REDIRECT_URL_KEY,
          history.location.pathname + history.location.search
        );
      }

      const isAuthenticated = await client.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await client.getUser();

        // set the access token for later use
        _accessToken = await client.getTokenSilently();

        // initially making blank, before select the Project
        window.localStorage.setItem('selectedProject', '{}');

        _setUser(user);
        setAccessToken(_accessToken);

        const userInfo = await getUserRole(user.sub);

        registerUserInfo(userInfo, isTrialUser);

        const {
          role,
          logins_count,
          is_trial_expired,
          is_trial_user,
          expired_in,
        } = userInfo;
        if (logins_count === 1 && !firstTimeUser) {
          enableWalkThrough();
          track(Event.SIGNUP);
        }
        setIsTrialUser(is_trial_user);
        setIsTrialExpired(is_trial_expired);
        setLoginCounts(logins_count);
        setUserRole(role);
        setExpiredIn(expired_in);
        const subscriptions = await getUserSubscriptions();
        setUserSubscriptions(subscriptions);

        const shareProjectInfoStatus = await getShareProjectInfoStatus(
          user.email
        );
        setShowShareProjectInfo(
          shareProjectInfoStatus?.show_info
            ? shareProjectInfoStatus?.show_info
            : false
        );

        const updateInfoStatus = await getUpdateInfoStatus(user.email);
        setShowJpUpdateInfo(
          updateInfoStatus?.job_postings
            ? updateInfoStatus?.job_postings
            : false
        );
        setShowIhUpdateInfo(
          updateInfoStatus?.insights_hub
            ? updateInfoStatus?.insights_hub
            : false
        );
        setShowDashboardModal(
          updateInfoStatus?.dashboard_visit
            ? updateInfoStatus?.dashboard_visit
            : false
        );
      }

      setAuthenticating(false);
    })();

    return () => setTokenHasExpired(false);

    //eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const maintenanceStatus = await getMaintenanceStatus(user?.id);
        if (
          JSON.stringify(isUnderMaintenance) === '{}' ||
          isUnderMaintenance?.status != maintenanceStatus?.status
        ) {
          setIsUnderMaintenance(maintenanceStatus);
        }
        setIsTrialExpired(maintenanceStatus.is_trial_expired);
        setExpiredIn(maintenanceStatus.expired_in);
        setIsTrialUser(maintenanceStatus.is_trial_user);
      } catch (error) {
        console.error('Error', error);
      }
    };
    const interval = setInterval(fetchData, 600000);
    return () => clearInterval(interval);
  }, [isUnderMaintenance, user?.id]);

  useEffect(() => {
    if (accessToken) {
      setProfile(user);
    }
  }, [user, accessToken]);

  if (isUnderMaintenance && isUnderMaintenance?.status === true) {
    return <MaintenancePage user={user} />;
  }

  if (authenticating) {
    return <AppLoading />;
  }

  const value = {
    isAuthenticated,
    isTrialExpired,
    user,
    userRole,
    userSubscriptions,
    tokenHasExpired,
    loginWithRedirect: (...args) => auth0Client.loginWithRedirect(...args),
    logout: () =>
      auth0Client.logout({
        returnTo: window.location.origin,
        client_id: AUTH0_CLIENT_ID,
      }),
    firstTimeUser,
    saveFirstTimeUser,
    enableWalkThrough,
    canShowWalkThrough,
    setCanShowWalkThrough,
    loginCounts,
    showShareProjectInfo,
    setShowShareProjectInfo,
    showJpUpdateInfo,
    setShowJpUpdateInfo,
    showIhUpdateInfo,
    setShowIhUpdateInfo,
    showDashboardModal,
    setShowDashboardModal,
    isTrialUser,
    expiredIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthContextProvider, getAccessToken, useAuthContext };
