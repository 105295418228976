import { LoadingStates } from '@components/button';
import Icon from '@components/icon';
import { Input, InputErrorMessage } from '@components/input';
import { Modal } from '@components/modal';
import Text from '@components/text';
import { checkIsBainUser, getUserHelpdeskMail } from '@util/email';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { PROJECT } from './constants';

import Fields from './Fields';
import styles from './ProjectsModal.module.scss';

const ProjectsModal = ({
  handleOk,
  handleCancel,
  isModalVisible,
  projectName,
  setProjectName,
  isProjectNameInvalid,
  projectCaseCode,
  setProjectCaseCode,
  isCaseCodeInvalid,
  setIsCaseCodeInvalid,
  user,
  modalContent,
  isLoading,
  formFields,
  setFormFields,
  disabled,
  setDisabled,
  caseTypeOptions,
  regionOptions,
  officeOptions,
  capabilityOptions,
  industriesOptions,
}) => {
  const userHelpdeskMail = getUserHelpdeskMail(checkIsBainUser(user.email));
  useEffect(() => {
    if (modalContent === PROJECT.RENAME) {
      // if available, case code is prepended to the project name e.g. '[po78] Test Project'
      const caseCode = projectName?.match(/(?:^\[(.*?)\])/);

      if (caseCode && Array.isArray(caseCode)) {
        setProjectName(state => {
          if (caseCode && state.split('] ')) {
            return state.split('] ')[1];
          }
          return state;
        });
        setProjectCaseCode(caseCode[1]);
      }
    }
  }, [modalContent, projectName, setProjectCaseCode, setProjectName]);

  useEffect(() => {
    if (modalContent === PROJECT.CREATE) {
      setProjectCaseCode('');
    }
  }, [modalContent, setProjectCaseCode]);

  const [isCaseCodeValid, setIsCaseCodeValid] = useState(false);

  // JSX
  return (
    <Modal
      className={styles.projectsModal}
      contentClassName={
        modalContent === PROJECT.DELETE
          ? styles.contentClassNameSmall
          : styles.contentClassName
      }
      headerText={
        modalContent === PROJECT.DELETE
          ? 'Are you Sure?'
          : modalContent === PROJECT.RENAME
          ? 'Rename project'
          : 'New Project'
      }
      hasCloseButton={false}
      centered
      footerButtonPropsList={[
        {
          title:
            modalContent === PROJECT.DELETE
              ? 'Confirm'
              : modalContent === PROJECT.RENAME
              ? 'Rename'
              : 'Create Project',
          type: 'primary',
          onClick: handleOk,
          loadingState: isLoading
            ? LoadingStates.LOADING
            : LoadingStates.NOT_LOADING,
          disabled:
            modalContent === PROJECT.DELETE
              ? false
              : modalContent === PROJECT.RENAME
              ? projectName.length
                ? checkIsBainUser(user.email)
                  ? disabled
                  : false
                : true
              : modalContent === PROJECT.CREATE
              ? projectName.length
                ? checkIsBainUser(user.email)
                  ? disabled
                  : false
                : true
              : false,
        },
        { title: 'Cancel', type: 'ghost', onClick: handleCancel },
      ]}
      maskStyle={{ background: 'rgba(0, 0, 0, 0.9)' }}
      isVisible={isModalVisible}
      contentScrollable={modalContent !== PROJECT.DELETE}
      contentComponent={
        <>
          {modalContent === PROJECT.DELETE ? (
            <div className={styles.fieldSet}>
              <Text type="BodySmallRegular">
                The project {projectName} will be permanently deleted.
              </Text>
            </div>
          ) : (
            <>
              <div className={styles.fieldSet}>
                <div
                  className={classnames(styles.font, styles.textLabel)}
                >{`Name of your ${
                  modalContent === PROJECT.CREATE ? 'new' : ''
                } project`}</div>
                <Input
                  placeholder="Type project name here"
                  className={
                    isProjectNameInvalid
                      ? styles.textInputError
                      : styles.textInput
                  }
                  onChange={e => setProjectName(e.target.value)}
                  value={projectName}
                  // onKeyDown={e => {
                  //   if (e.key === 'Enter') {
                  //     handleOk();
                  //   }
                  // }}
                />
                {isProjectNameInvalid && (
                  <InputErrorMessage errorMessage="Please enter a project name." />
                )}
              </div>
              {checkIsBainUser(user.email) && (
                <CaseCodeInputField
                  isCaseCodeInvalid={isCaseCodeInvalid}
                  setIsCaseCodeInvalid={setIsCaseCodeInvalid}
                  projectCaseCode={projectCaseCode}
                  setProjectCaseCode={setProjectCaseCode}
                  handleOk={handleOk}
                  view="insightsHub"
                  setDisabled={setDisabled}
                  modalContent={modalContent}
                  isCaseCodeValid={isCaseCodeValid}
                  setIsCaseCodeValid={setIsCaseCodeValid}
                  userHelpdeskMail={userHelpdeskMail}
                />
              )}
              {checkIsBainUser(user.email) && (
                <Fields
                  setDisabled={setDisabled}
                  formFields={formFields}
                  setFormFields={setFormFields}
                  caseTypeOptions={caseTypeOptions}
                  regionOptions={regionOptions}
                  officeOptions={officeOptions}
                  capabilityOptions={capabilityOptions}
                  industriesOptions={industriesOptions}
                />
              )}
            </>
          )}
        </>
      }
    />
  );
};

ProjectsModal.propTypes = {
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  isModalVisible: PropTypes.bool,
  isProjectNameInvalid: PropTypes.bool,
  setProjectName: PropTypes.func,
  projectName: PropTypes.string,
  isCaseCodeInvalid: PropTypes.bool,
  setProjectCaseCode: PropTypes.func,
  projectCaseCode: PropTypes.string,
  user: PropTypes.object,
  modalContent: PropTypes.oneOf(Object.values(PROJECT)),
  isLoading: PropTypes.bool,
  setDisabled: PropTypes.func,
  disabled: PropTypes.bool,
};

export const CaseCodeInputField = ({
  isCaseCodeInvalid,
  projectCaseCode,
  setProjectCaseCode,
  handleOk,
  view,
  modalContent,
  isCaseCodeValid,
  setIsCaseCodeValid,
  userHelpdeskMail,
  // setDisabled,
}) => {
  const [hasInteractedWithCaseCode, setHasInteractedWithCaseCode] = useState(
    false
  );

  const caseCodeValidation = code => {
    const invalidCodes = ['DEMO', 'TEST', 'ABCD'];
    const isSameSequence = /^(.)\1+$/.test(code);
    const isFourDigitsWithLettersAndNumbers = /^[a-zA-Z]+\d{4}$/.test(code);

    if (
      invalidCodes.includes(code.toUpperCase()) ||
      isSameSequence ||
      ((code.length === 3 || code.length === 4) &&
        !isFourDigitsWithLettersAndNumbers)
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const validity = caseCodeValidation(projectCaseCode);
    (modalContent === PROJECT.CREATE || modalContent === PROJECT.RENAME) &&
      setIsCaseCodeValid(validity);
  }, [modalContent, projectCaseCode, setIsCaseCodeValid]);

  return (
    <div className={styles.fieldSet}>
      {
        <div>
          <div className={classnames(styles.font, styles.textLabel)}>
            Case code
          </div>
          <Input
            className={
              isCaseCodeInvalid ? styles.textInputError : styles.textInput
            }
            placeholder="Type case code here"
            value={projectCaseCode}
            onChange={e => {
              setProjectCaseCode(e.target.value);
            }}
            rightComponent={
              !['insightsHub', 'databox'].includes(view) && (
                <Tooltip
                  overlayClassName={styles.tooltipContainer}
                  placement="bottom"
                  color="var(--gray-100)"
                  title={() => (
                    <div className={styles.tooltipContent}>
                      <div className={styles.tooltipText}>
                        Job posting access is included in any Insights Hub
                        project case code. When job posting is accessed alone,
                        there is a $5K charge to the case code. Please contact{' '}
                        <span className={styles.tooltipTextEmail}>
                          {userHelpdeskMail}
                        </span>{' '}
                        if you don’t find the data you need and would like to
                        request a custom data pull, or if you don’t end up using
                        this data and want the charge to be waived, or for any
                        questions.
                      </div>
                    </div>
                  )}
                >
                  <Icon
                    icon="infoIcon"
                    className={
                      isCaseCodeInvalid
                        ? styles.infoIconButtonError
                        : styles.infoIconButton
                    }
                    viewBox="-0.5 1 13 10"
                  />
                </Tooltip>
              )
            }
            onKeyDown={e => {
              if (e.key === 'Enter' && view === 'jobPostings') {
                handleOk();
              }
            }}
            onBlur={() => setHasInteractedWithCaseCode(true)}
          />
          {((isCaseCodeInvalid && view === 'jobPostings') ||
            (!isCaseCodeValid &&
              hasInteractedWithCaseCode &&
              view !== 'jobPostings')) && (
            <InputErrorMessage errorMessage="Case code must be alphanumeric, 3 to 4 characters long." />
          )}
        </div>
      }
      <Text
        type="LegendRegular"
        style={{
          display: 'block',
          marginTop: view === 'insightsHub' ? '0px' : '20px',
          color: 'var(--gray-500)',
          textAlign: 'justify',
        }}
      >
        {view === 'insightsHub' && (
          <p style={{ margin: 0 }}>
            Aura platform is free to explore but{' '}
            <b>a fee of $9.5K for GP/PEG cases and $1.5K for CD/IP cases</b>
            <br />
            will be applied if you share the Aura data and its output with your
            client or use it on a CD/IP case.
            <br />
            Please reach out to{' '}
            <a
              href={`mailto:` + userHelpdeskMail}
              target="_blank"
              onClick={e => e.stopPropagation()}
            >
              {userHelpdeskMail}
            </a>{' '}
            if you have any questions. <br />
            Please Note: DEMO, TEST, ABCD or same sequence of numbers or letters
            are invalid case codes <br /> and if 4 characters case code is
            entered, only a sequence of letters and numbers are allowed.
          </p>
        )}
        {view === 'databox' && (
          <p style={{ margin: 0 }}>
            Aura platform is free to explore but{' '}
            <b>
              a fee of $0.75 per profile up to 5K profiles and $5K for 5K+
              profiles up to 150K profiles
            </b>
            <br />
            for this module will be applied if you share the Aura data and its
            output with your client or use it on a CD/IP case.
            <br />
            Please reach out to{' '}
            <a
              href={`mailto:` + userHelpdeskMail}
              target="_blank"
              onClick={e => e.stopPropagation()}
            >
              {userHelpdeskMail}
            </a>{' '}
            if you have any questions.
          </p>
        )}
      </Text>
    </div>
  );
};

CaseCodeInputField.propTypes = {
  isCaseCodeInvalid: PropTypes.bool,
  projectCaseCode: PropTypes.string,
  setProjectCaseCode: PropTypes.func,
  handleOk: PropTypes.func,
  setDisabled: PropTypes.func,
};

export default ProjectsModal;
