export const checkIsTigerUser = userEmail =>
  /@tigerglobal.com$/.test(userEmail) ||
  /\+tigerglobal@aura.ceo/.test(userEmail) ||
  userEmail === 'nicholas.reyner@bain.com';

export const checkIsBainUser = userEmail =>
  /@bain.com$/.test(userEmail) || /\+bain@aura.ceo/.test(userEmail);

export const checkIsAuraintelUser = userEmail =>
  /@auraintel.com/.test(userEmail);

export const checkIsAuraUser = userEmail =>
  /@bain.com$/.test(userEmail) || /@aura.ceo/.test(userEmail);

export const checkIsDragoneerUser = userEmail =>
  /@dragoneer.com$/.test(userEmail) || /\+dragoneer@aura.ceo/.test(userEmail);

export const checkIsGlenviewcapitalUser = userEmail =>
  /@glenviewcapital.com$/.test(userEmail) ||
  /\+glenviewcapital@aura.ceo/.test(userEmail);

export const getDomain = email => {
  if (email?.includes('@')) {
    return email.split('@')[1];
  }
};

export const getUserHelpdeskMail = userEmail =>
  userEmail ? 'bain@auraintel.com' : 'support@auraintel.com';
