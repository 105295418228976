import React, { useEffect, useState } from 'react';
import styles from './ProjectsModal.module.scss';
import classnames from 'classnames';
import { Input, InputErrorMessage } from '@components/input';
import Select from 'react-select';

function Fields({
  setDisabled,
  formFields,
  setFormFields,
  caseTypeOptions,
  regionOptions,
  officeOptions,
  capabilityOptions,
  industriesOptions,
}) {
  // State

  //   HandleChanges
  const handleChange = (event, type) => {
    let options, setSelection, defaultOption;

    switch (type) {
      case 'caseType':
        options = caseTypeOptions;
        setSelection = setFormFields;
        defaultOption = { ...formFields, caseType: caseTypeOptions[0] };
        break;
      case 'region':
        options = regionOptions;
        setSelection = setFormFields;
        defaultOption = { ...formFields, region: regionOptions[0] };
        break;
      case 'office':
        options = officeOptions;
        setSelection = setFormFields;
        defaultOption = { ...formFields, office: officeOptions[0] };
        break;
      default:
        return; // or throw an error if needed
    }

    const selectedOption = options.find(option => option === event);
    setSelection(prevState =>
      selectedOption ? { ...prevState, [type]: selectedOption } : defaultOption
    );
  };
  const handleIndustryChange = selectedOptions => {
    setFormFields(prevState => ({
      ...prevState,
      industry: selectedOptions,
    }));
  };
  const handleCapabilityChange = selectedOptions => {
    setFormFields(prevState => ({
      ...prevState,
      capability: selectedOptions,
    }));
  };

  //   Conditions
  const [isCaseManagerInvalid, setIsCaseManagerInvalid] = useState(false);
  const [
    hasInteractedWithCaseManager,
    setHasInteractedWithCaseManager,
  ] = useState(false);

  const handleCaseManagerChange = e => {
    const inputValue = e.target.value;
    setIsCaseManagerInvalid(false);
    const words = inputValue.split(' ').filter(word => word.trim() !== '');
    setFormFields(prevState => ({
      ...prevState,
      caseManager: inputValue,
    }));
    if (words.length >= 2) {
      setIsCaseManagerInvalid(false);
    } else {
      setIsCaseManagerInvalid(true);
    }
  };

  const [isPartnerInvalid, setIsPartnerInvalid] = useState(false);
  const [hasInteractedWithPartner, setHasInteractedWithPartner] = useState(
    false
  );

  const handlePartnerChange = e => {
    const inputValue = e.target.value;
    setIsPartnerInvalid(false);
    const words = inputValue.split(' ').filter(word => word.trim() !== '');
    setFormFields(prevState => ({
      ...prevState,
      partner: inputValue,
    }));
    if (words.length >= 2) {
      setIsPartnerInvalid(false);
    } else {
      setIsPartnerInvalid(true);
    }
  };

  //   Styles
  const multiSelectStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f7f8fb', border: 0 }),
  };
  const singleSelectStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f7f8fb', border: 0 }),
    option: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      color: '#667c8a',
      cursor: 'pointer',
    }),
  };

  useEffect(() => {
    for (const key in formFields) {
      if (
        formFields[key] === '' ||
        (Array.isArray(formFields[key]) && formFields[key].length === 0)
      ) {
        setDisabled(true);
        return;
      }
      if (isPartnerInvalid || isCaseManagerInvalid) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [formFields, isCaseManagerInvalid, isPartnerInvalid, setDisabled]);

  // JSX
  return (
    <div>
      {/* Case Type */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>
          Case Type
        </div>
        <div style={{ margin: '8px 0 4px 0' }}>
          <Select
            name="office"
            options={caseTypeOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select case type"
            onChange={event => handleChange(event, 'caseType')}
            styles={singleSelectStyles}
            defaultValue={formFields?.caseType}
          />
        </div>
      </div>
      {/* Region */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>Region</div>
        <div style={{ margin: '8px 0 4px 0' }}>
          <Select
            name="region"
            options={regionOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select region"
            onChange={event => handleChange(event, 'region')}
            styles={singleSelectStyles}
            defaultValue={formFields?.region}
          />
        </div>
      </div>
      {/* Office */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>Office</div>
        <div style={{ margin: '8px 0 4px 0' }}>
          <Select
            name="office"
            options={officeOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select office"
            onChange={event => handleChange(event, 'office')}
            styles={singleSelectStyles}
            defaultValue={formFields?.office}
          />
        </div>
      </div>
      {/* Partner */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>Partner</div>
        <Input
          placeholder="Type partner name here"
          className={styles.textInput}
          onChange={e => handlePartnerChange(e)}
          value={formFields.partner}
          onBlur={() => setHasInteractedWithPartner(true)}
        />

        {isPartnerInvalid && hasInteractedWithPartner && (
          <InputErrorMessage errorMessage="Partner field must contain at least two words (name and surname)." />
        )}
      </div>
      {/* Case Manager */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>
          Case Manager
        </div>
        <Input
          placeholder="Type case manager name here"
          className={styles.textInput}
          onChange={e => handleCaseManagerChange(e)}
          value={formFields.caseManager}
          onBlur={() => setHasInteractedWithCaseManager(true)}
        />
        {isCaseManagerInvalid && hasInteractedWithCaseManager && (
          <InputErrorMessage errorMessage="Case manager must contain at least two words (name and surname)." />
        )}
      </div>
      {/* capability */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>
          Top-level capability
        </div>
        <div style={{ margin: '8px 0 4px 0' }}>
          <Select
            isMulti
            name="colors"
            options={capabilityOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Top-level capability"
            closeMenuOnSelect={false}
            onChange={handleCapabilityChange}
            styles={multiSelectStyles}
            value={formFields?.capability}
          />
        </div>
      </div>
      {/* industry */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>
          Top-level industry
        </div>
        <div style={{ margin: '8px 0 4px 0' }}>
          <Select
            isMulti
            name="colors"
            options={industriesOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Top-level industries"
            closeMenuOnSelect={false}
            onChange={handleIndustryChange}
            styles={multiSelectStyles}
            value={formFields?.industry}
          />
        </div>
      </div>
      {/* Client name */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>
          Client name
        </div>
        <Input
          placeholder="Type client name here"
          className={styles.textInput}
          onChange={e =>
            setFormFields(prevState => ({
              ...prevState,
              clientName: e.target.value,
            }))
          }
          value={formFields.clientName}
        />
      </div>
    </div>
  );
}

export default Fields;
